import React, {Fragment, ReactNode, useRef,FC} from "react";
import Link from "next/link";
import Image from "next/image";
import {useDispatch} from "react-redux";
import {setBuilderUrl, setPopup, setPopupName} from "../../../store/general";
import useIsInView from "../../../hooks/useInView";
import HoverImage from "../HoverImage";
import cls from "../builder.module.scss"

interface IProps {
    item: {
        switchValue: boolean | null;
        linkValue?: string;
        customClassNames: string;
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        },
        imageStyles: { width: "150%", marginLeft: "-25%" } | {};
        imageSwitchStyles: Record<string, string>;
        setInlineStyles: Record<string, string>
        linkTarget: "_self" | "_blank"
        defaultImage: string
        hoverImage?: string;
        hoverAnimation: string
    };
    dbName: string;
    lcpElement?: boolean;
    isMobile: boolean
}

const ImageUpload: FC<IProps> = (
    {
        item: {
            switchValue,
            linkValue = "",
            linkTarget,
            customClassNames,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                parentStyles = {},
                styles: imageSwitchStyles,
            } = {},
            imageStyles,
            defaultImage,
            hoverImage,
            hoverAnimation
        },
        isMobile,
        lcpElement,
        dbName
    }
) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const handleModalOpen = (): void => {
        if (switchValue) {
            dispatch(setPopupName("zoom_in"));
            dispatch(setPopup(true));
            dispatch(setBuilderUrl(defaultImage))
        }
    }
    const imagePropHandler = {
        "true": {},
        "false": {onClick: handleModalOpen},
    }
    const LinkLayoutToggle = ({children}: { children: ReactNode }) => {
        const jsxWithLayout = {
            "true": <Link href={linkValue as string}>
                <a target={linkTarget}>
                    {children}
                </a>
            </Link>,
            "false": <Fragment>{children}</Fragment>
        }
        return jsxWithLayout[`${!!linkValue}`]
    }
    const animationExist = !!dataAos && dataAos !== "none"
    const inView = animationExist ? useIsInView(ref, !!dataAos) : true;
    const dataAosProp = animationExist ? {"data-aos" : dataAos} : {}
    const handleLcpImageSize = () => {
        if (!lcpElement || !isMobile) return {}
        const { margin = "", width = "" } = { ...imageSwitchStyles, ...imageStyles } || {};
        if (margin && (!width || width === "unset")) {
            const splittedMargin = margin.split(" ");
            if (splittedMargin?.length === 1) return {width: `calc(100% - ${splittedMargin[0]} - ${splittedMargin[0]})`}
            if (splittedMargin?.length === 2 || splittedMargin?.length === 3) {
                return {width: `calc(100% - ${splittedMargin[1]} - ${splittedMargin[1]})`}
            }
            if (splittedMargin?.length >= 4) {
                const [_, marginRight, __, marginLeft] = splittedMargin
                return {width: `calc(100% - ${marginRight} - ${marginLeft})`}
            }
        }
        return {}
    }

    return <div
        {...dataAosProp}
        style={lcpElement && isMobile ? {...parentStyles, width: "100%"} : parentStyles}
        className={!inView ? "dataAosRef" : ""}
    >
        <LinkLayoutToggle>
            <div className={`${customClassNames} ${cls[hoverAnimation ?? "none"]}`} style={{
                ...imageSwitchStyles,
                ...imageStyles,
                maxWidth: "100%",
                position: 'relative',
                ...handleLcpImageSize()
            }} ref={ref}>
                {lcpElement ? (
                    <img
                        src={defaultImage}
                        alt="Largest Image"
                        loading="eager"
                        data-fetchpriority="high"
                        width={isMobile ? "500" : "unset"}
                        height={isMobile ? "250" : "unset"}
                        {...imagePropHandler[`${!!linkValue}`]}
                    />
                ) : (
                    <img
                        src={defaultImage}
                        alt="builder image"
                        loading="lazy"
                        style={{...imageSwitchStyles, ...imageStyles}}
                        {...imagePropHandler[`${!!linkValue}`]}
                    />
                )}
                <HoverImage
                    src={hoverImage}
                    css={{...imageSwitchStyles, ...imageStyles}}
                />
            </div>
        </LinkLayoutToggle>
    </div>
};

export default ImageUpload;