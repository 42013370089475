import React, {FC, useEffect} from "react";
import cls from "./forms.module.scss"
import {
    Button,
    Checkbox,
    Heading,
    Input,
    InputDate,
    InputFile,
    InputNumber,
    Radio,
    Select,
    TextArea,
} from "./FormAtoms";
import {useDispatch} from "react-redux";
import {addAndResetForm, setFormErrors} from "../../../store/formbuilder";

interface IProps {
    item: {
        customClassNames: string;
        buttonValue: string;
        settings: [];
        blog: {
            blogs: Array<any>,
            category: {
                id: number,
                slug: string
            },
            quantity: number
        };
        form: {
            id: number,
            name: string
        },
        formComponentInfo: {
            data: {
                id: number,
                action_json: {
                    api: { status: string }
                    database: { unique_field: string }
                    email: { status: string }
                },
                code: string,
                has_captcha: boolean,
                is_public: boolean,
                locale: string,
                name: string,
                status: boolean,
                submission_json: {
                    on_failure: {
                        action: string,
                        content: string
                    },
                    on_success: {
                        action: string,
                        content: string
                    }
                }
                form_builder_json: Array<{
                    type: string,
                    value: string,
                    multiple: boolean,
                    values: Array<{
                        label: string,
                        value: string,
                        selected: boolean,
                    }>,
                }>
                created_at: string,
                updated_at: string
            }
        }
        linkValueButton: {
            id: string;
            value: string;
            checked: boolean
        }
    },
    recaptchaActive: string
}

const FormBuilderComponent: FC<IProps> = (
    {
        item: {
            customClassNames,
            formComponentInfo: {
                data: {
                    id,
                    code,
                    form_builder_json = []
                }
            }
        },
        recaptchaActive
    }
) => {
    console.log(customClassNames, "customClassNames123123")
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(addAndResetForm({
            id,
            formData: form_builder_json
        }))
        dispatch(setFormErrors({}))
    }, []);

    const handleElement = (elem: any, index: number): Record<string, React.JSX.Element> => {
        return {
            "header": <Heading key={index} formId={id} index={index} element={elem}/>,
            "paragraph": <Heading key={index} formId={id} index={index} element={elem}/>,
            "text": <Input key={index} formId={id} index={index} element={elem}/>,
            "number": <InputNumber key={index} formId={id} index={index} element={elem}/>,
            "date": <InputDate key={index} formId={id} index={index} element={elem}/>,
            "file": <InputFile key={index} formId={id} index={index} element={elem}/>,
            "button": <Button key={index} formId={id} formCode={code} index={index} element={elem} formData={form_builder_json} recaptchaActive={recaptchaActive}/>,
            "checkbox-group": <Checkbox key={index} formId={id} index={index} element={elem}/>,
            "radio-group": <Radio key={index} formId={id} index={index} element={elem}/>,
            "textarea": <TextArea key={index} formId={id} index={index} element={elem}/>,
            "select": <Select key={index} formId={id} index={index} element={elem}/>
        }
    }

    return <div className={`${cls.form} ${customClassNames}`}
        // data-aos={animation}
    >
        {form_builder_json.map((el, index) => {
            return handleElement(el, index)[el.type]
        })}
    </div>
}

export default FormBuilderComponent;